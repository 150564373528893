import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../../Helpers';
import BasePage from '../Base';

export const OfferItem = ({ title, description, more }) => (
  <div className="m-landing-page-description pt-5">
    {title && <h3 className="has-text-weight-bold pb-2">{title}</h3>}
    {description && <p className="has-text-weight-normal pb-2">{description}</p>}
    {more && <p className="has-text-weight-normal pb-2">{more}</p>}
  </div>
);

OfferItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  more: PropTypes.string,
};

OfferItem.defaultProps = {
  description: '',
  more: '',
};

const AffiliateLandingPageV2 = ({ data }) => (
  <BasePage data={data}>
    <div className="m-landing-page-container" style={{ backgroundImage: `url( ${data.background_image?.meta.download_url} )`, paddingTop: 0 }}>
      {data.header_image?.meta?.download_url && (
        <img className="m-landing-page-header" src={data.header_image.meta.download_url} alt={data.header_image?.title || 'header image'} />
      )}
      <div className="container bg-secondary">
        <div className="m-landing-page-body">
          <h2 className="m-landing-page-title-mid has-text-white px-4" dangerouslySetInnerHTML={{ __html: data.offer_name }} />
          <div className="m-landing-page-description px-4" dangerouslySetInnerHTML={{ __html: data.body }} />
          {data.button_target && (
            <div className="buttons">
              <NavLink
                className="button is-centered is-large is-dark-green"
                to={getPathname(data.button_target.html_url)}
                dangerouslySetInnerHTML={{ __html: data.button_text }}
              />
            </div>
          )}
          <div className="m-landing-page-items px-4">
            {data.offer.map(item => (
              <OfferItem key={item.id} {...item.value} />
            ))}
          </div>
          {data.banner?.meta?.download_url && (
            <img src={data.banner.meta.download_url} alt={data.banner?.title || 'banner image'} />
          )}
          <div className="m-landing-page-description color-white has-text-weight-normal content px-2" dangerouslySetInnerHTML={{ __html: data.body_two }} />

        </div>
      </div>
    </div>
  </BasePage>
);

AffiliateLandingPageV2.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AffiliateLandingPageV2;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import ArticleMenu from '../Article/ArticleMenu';
import FeaturedArticle from '../Article/FeaturedArticle';
import ArticleThumbnailList, { PlaceholderThumbnailList } from '../Article/ArticleThumbnailList';
import BlogWidget from '../Blog/BlogWidget';
import BasePage from '../Base';
import { adjustRecurrences } from './promotionHelper';
import { getBonusOffer } from '../../Helpers';
import PromotionWrapper from './PromotionWrapper';
import PromotionHeader from './PromotionHeader';

class PromotionPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      articlesFetched: false,
      nextMonthArticles: [],
      nextMonthArticlesFetched: false,
      thirdMonthArticles: [],
      thirdMonthArticlesFetched: false,
      bonusOffer: {},
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (!data || !data.id) {
      console.error('Data or data.id is undefined:', data);
      return;
    }
    this.fetchArticlesForMonths([0, 1, 2], ['articles', 'nextMonthArticles', 'thirdMonthArticles']);
    this.getBonus(data.id);
  }

  fetchArticlesForMonths = async (monthOffsets, stateKeys) => {
    const { data } = this.props;
    const today = moment().format('YYYY-MM-DD');

    const urls = monthOffsets.map((monthOffset, index) => {
      const startDate = stateKeys[index] === 'articles' ? moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD') : moment().add(monthOffset, 'months').startOf('month').format('YYYY-MM-DD');
      const endDate = moment().add(monthOffset, 'months').endOf('month').format('YYYY-MM-DD');
      return {
        url: `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&valid_from__gte=${startDate}&valid_to__gte=${today}&valid_from__lte=${endDate}&valid_to__gte=${today}&show_in_calendar=true&recurrences=true&order=valid_from`,
        stateKey: stateKeys[index],
      };
    });

    try {
      await Promise.all(
        urls.map(({ url, stateKey }) => this.fetchArticles(url, stateKey)),
      );
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  fetchArticles = async (url, stateName) => {
    try {
      const result = await new Casino.FetchContent(url).perform();
      const items = adjustRecurrences(result.items());
      this.setState({
        [stateName]: items,
        [`${stateName}Fetched`]: true,
      });
      console.log(`Fetched ${items.length} articles for ${stateName}`);
    } catch (error) {
      console.error(`Error fetching articles for ${stateName}:`, error);
    }
  };

  getBonus = async (id) => {
    try {
      const bonusOffer = await getBonusOffer(id);
      this.setState({ bonusOffer });
    } catch (error) {
      console.error('Error fetching bonus offer:', error);
    }
  };

  renderArticleThumbnailList = (items, title) => (
    items?.length > 0 ? <ArticleThumbnailList items={items} title={title} needDay /> : null
  );

  render() {
    const {
      articles,
      articlesFetched,
      nextMonthArticles,
      nextMonthArticlesFetched,
      thirdMonthArticles,
      thirdMonthArticlesFetched,
      bonusOffer,
    } = this.state;

    const { t, i18n, data } = this.props;
    let menuHandle = `${i18n.language}_promotion-menu`;
    let menuTitle = t('common:promotions');

    if (data.promotion_menu) {
      menuHandle = data.promotion_menu.handle;
      menuTitle = data.promotion_menu.title;
    }

    return (
      <BasePage data={data}>
        <PromotionWrapper>
          <PromotionHeader>
            {data.featured_promotion && (
              <FeaturedArticle
                data={bonusOffer.currentBonus}
                title={data.title}
                showTitle={data.meta.show_title}
              />
            )}
            {data.featured_blog && (
              <BlogWidget
                id={data.featured_blog.id}
                linkTo={data.featured_blog.html_url}
              />
            )}
          </PromotionHeader>

          <ArticleMenu handle={menuHandle} title={menuTitle} />

          {!articlesFetched && !nextMonthArticlesFetched && !thirdMonthArticlesFetched ? (
            <PlaceholderThumbnailList />
          ) : (
            <>
              {this.renderArticleThumbnailList(articles)}
              {this.renderArticleThumbnailList(nextMonthArticles, `${t('common:next_month')}:`)}
              {this.renderArticleThumbnailList(thirdMonthArticles, `${t('common:coming_soon')}:`)}
            </>
          )}
        </PromotionWrapper>
      </BasePage>
    );
  }
}

PromotionPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};

export default withTranslation()(PromotionPage);

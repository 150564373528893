import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, handleClickOutside } from '../../Helpers';
import FavoriteGamesHeartIcon from './FavoriteGamesHeartIcon';
import useQuery from '../../../hooks/useQuery';
import useGames from '../../../hooks/games/useGames';
import useActiveGames from '../../../hooks/games/useActiveGames';

const GameMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const { addQuery, removeQuery, query } = useQuery();
  const { categories, activeGamesCategory } = useGames();
  const { activeCategory } = useActiveGames();

  useEffect(() => {
    handleClickOutside(menuRef, () => setIsOpen(false));
  }, [menuRef]);

  function handleClick(id, categorySlug) {
    if (id === activeCategory?.id || categorySlug === query?.get('category')) {
      removeQuery('category');
    } else {
      addQuery('category', categorySlug);
    }
  }

  const menuItems = categories?.items
    ?.filter((category) => category?.display_filter)
    ?.map((category) => (
      <button
        type="button"
        key={category.id}
        onClick={() => handleClick(category.id, category.slug)}
        className={`navbar-item ${
          category.id === activeCategory?.id ? 'is-active' : ''
        }`}
      >
        {category.showIcon && (
          <FavoriteGamesHeartIcon
            favorite
            outlineColor="#552C88"
            categoryItem
          />
        )}
        {category.name}
      </button>
    ));

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="game navigation"
      ref={menuRef}
    >
      <div className="navbar-start">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`m-game-nav-button is-flex-touch is-hidden-desktop ${
            isOpen ? ' is-active' : ''
          }`}
          aria-label="game-menu-button"
          aria-expanded="false"
          data-menu-target="navbarGameNav"
        >
          <div className="is-size-7-mobile" aria-label="game-menu-label">
            {capitalize(t('categories'))}
          </div>
          <div
            className={`navbar-burger burger is-flex-touch is-static ${
              isOpen ? ' is-active' : ''
            }`}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </button>
        <div
          className={`navbar-menu m-game-nav-menu ${
            isOpen ? ' is-active' : ''
          }`}
          id="navbarGameNav"
        >
          <button
            type="button"
            key="all-games"
            onClick={() => handleClick(null, 'all-games')}
            className={`navbar-item ${
              query?.get('category') === 'all-games' ? 'is-active' : ''
            }`}
          >
            All games
          </button>
          {activeGamesCategory?.['top-games'] ? (
            <button
              type="button"
              key="top-games"
              onClick={() => handleClick(null, 'top-games')}
              className={`navbar-item ${
                query?.get('category') === 'top-games' ? 'is-active' : ''
              }`}
            >
              Top Games
            </button>
          ) : null}
          {activeGamesCategory?.["recommended-games"] ? (
            <button
              type="button"
              key="recommended-games"
              onClick={() => handleClick(null, 'recommended')}
              className={`navbar-item ${
                query?.get('category') === 'recommended' ? 'is-active' : ''
              }`}
            >
              Recommended
            </button>
          ) : null}
          {activeGamesCategory?.newGames ? (
            <button
              type="button"
              key="new-games"
              onClick={() => handleClick(null, 'new-games')}
              className={`navbar-item ${
                query?.get('category') === 'new-games' ? 'is-active' : ''
              }`}
            >
              New
            </button>
          ) : null}
          {menuItems}
        </div>
      </div>
    </nav>
  );
};

export default GameMenu;

/* eslint-disable import/prefer-default-export */

import { useFetchGames, GamesKeys } from './useFetchGames';
import usePlayer from '../usePlayer';
import { getSessionCookie } from '../../components/Helpers';

export function useGetPeopleArePlayingGames() {
  const { player } = usePlayer();
  const session = getSessionCookie();
  const partyId = session?.partyId ?? player?.partyId;
  const hasPartyId = !!partyId;

  const { games, state, response } = useFetchGames(
    GamesKeys.popularGames,
    hasPartyId ? 'because_you_played' : 'players_like_you',
    partyId,
    true,
  );

  return {
    games,
    state,
    response,
  };
}

import { calculateMoreGames } from '../../actions/gamesActions/GamesActionHelpers';
import { FAVORITE_GAMES_CATEGORY_ID } from '../../actions/gamesActions/gamesAction';

const initialState = {
  isLoading: true,
  isFetching: false,
  error: {},
  categories: { isLoading: true, items: [], count: 0 },
  providers: {
    isLoading: true,
    items: [],
    count: 0,
    notAllowedProviders: [],
    allowedProviders: [],
  },
  gamesByCategories: {},
  gamesByProviders: {},
  gamesByIds: {},
  filtered: {
    games: [],
  },
  favoriteGames: {
    isLoading: true,
    games: [],
  },
  countryPreview: '',
  searchText: '',
  activeGamesCategory: {
    topGames: true,
    recommendedGames: true,
    peopleArePlayingGames: true,
  },
};

const gamesReducer = (state = initialState, action) => {
  if (action.type === 'SET_CATEGORIES_AND_PROVIDERS') {
    const { favoriteGames, categories, providers, initialUpdate } =
      action.payload;

    const dataToSet = {
      ...state,
      isLoading: false,
      categories: {
        ...state.categories,
        isLoading: false,
        ...categories,
      },
      providers: {
        ...state.categories,
        isLoading: false,
        ...providers,
        items: providers.items?.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        ),
      },
      initialUpdate,
    };

    if (favoriteGames) dataToSet.favoriteGames = favoriteGames;

    return {
      ...state,
      ...dataToSet,
    };
  }

  if (action.type === 'SET_GAMES_BY_CATEGORY') {
    const { id, ...rest } = action.payload;

    if (id === FAVORITE_GAMES_CATEGORY_ID) return state;

    let mergedCategoryGamesByIds = {};

    rest.games.forEach((game) => {
      const { id: gameId, ...gameDetails } = game;

      mergedCategoryGamesByIds = {
        ...mergedCategoryGamesByIds,
        [gameId]: { id: gameId, ...gameDetails },
      };
    });

    return {
      ...state,
      isLoading: false,
      gamesByCategories: {
        ...state.gamesByCategories,
        [id]: {
          id,
          ...rest,
        },
      },
      gamesByIds: {
        ...state.gamesByIds,
        ...mergedCategoryGamesByIds,
      },
    };
  }

  if (action.type === 'SET_GAMES_BY_PROVIDER') {
    const { id, ...rest } = action.payload;
    let mergedProviderGamesByIds = {};

    rest.games.forEach((game) => {
      const { id: gameId, ...gameDetails } = game;

      mergedProviderGamesByIds = {
        ...mergedProviderGamesByIds,
        [gameId]: { id: gameId, ...gameDetails },
      };
    });

    return {
      ...state,
      isLoading: false,
      gamesByProviders: {
        ...state.gamesByProviders,
        [id]: {
          id,
          ...rest,
        },
      },
      gamesByIds: {
        ...state.gamesByIds,
        ...mergedProviderGamesByIds,
      },
    };
  }

  if (action.type === 'UPDATE_GAMES') {
    const { gamesByCategories, ...restData } = action.payload;

    const stateToUpdate = {
      ...state,
      categories: { ...state.categories, ...restData.categories },
      providers: {
        ...state.providers,
        ...restData.providers,
        items: restData.providers.items?.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        ),
      },
      gamesByCategories: {
        ...state.gamesByCategories,
        ...gamesByCategories,
      },
      favoriteGames: {
        ...state.favoriteGames,
        ...restData.favoriteGames,
      },
      activeGame: restData.activeGame,
    };

    if (gamesByCategories) stateToUpdate.gamesByCategories = gamesByCategories;

    return { ...stateToUpdate };
  }

  if (action.type === 'LOAD_MORE') {
    const categoryId = action.payload;
    const category = state.gamesByCategories[categoryId];

    if (!category?.id) return state;

    const { loadAllGames, moreGamesToDisplay, displayGamesCount } = category;

    category.displayGamesCount = loadAllGames
      ? Infinity
      : displayGamesCount + moreGamesToDisplay;

    if (!loadAllGames) {
      const moreGames = calculateMoreGames(
        category.games,
        category.showWinnersList,
        category.displayGamesCount
      );

      category.displayGamesCount += moreGames;
    }

    return {
      ...state,
      gamesByCategories: {
        ...state.gamesByCategories,
        [categoryId]: category,
      },
    };
  }

  if (action.type === 'LOAD_MORE_FROM_CMS') {
    const { providerId, fetchedGames, session, totalCount, nextUrl } =
      action.payload;
    const {
      providers: { notAllowedProviders },
    } = state;

    const currentProvider = state.gamesByProviders[providerId];

    let gamesArray = fetchedGames;

    if (session) {
      if (notAllowedProviders.length) {
        const notAllowedProviderIds = notAllowedProviders.map(
          (provider) => provider.id
        );

        gamesArray = gamesArray.filter(
          (game) => !notAllowedProviderIds.includes(game.provider.id)
        );
      }
    }

    currentProvider.games = [...currentProvider.games, ...gamesArray];
    currentProvider.count += gamesArray.length;
    currentProvider.displayGamesCount += gamesArray.length;
    currentProvider.nextUrl = nextUrl;
    currentProvider.isFetching = false;

    if (!currentProvider.totalCount) {
      currentProvider.totalCount = totalCount;
    }

    return {
      ...state,
      gamesByProviders: {
        ...state.gamesByProviders,
        [providerId]: {
          ...currentProvider,
        },
      },
    };
  }

  if (action.type === 'SET_IS_FETCHING') {
    return {
      ...state,
      isFetching: action.payload,
    };
  }

  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      isLoading: false,
      error: { ...state.error, ...action.payload },
    };
  }

  if (action.type === 'SET_SEARCHED_GAMES') {
    if (!action.payload?.error) {
      let mergedProviderGamesByIds = {};

      action.payload.games.forEach((game) => {
        const { id: gameId } = game;
  
        mergedProviderGamesByIds = {
          ...mergedProviderGamesByIds,
          [gameId]: { id: gameId, ...game },
        };
      });

      return {
        ...state,
        isFetching: false,
        filtered: {
          ...action.payload,
        },
        gamesByIds: {
          ...state.gamesByIds,
          ...mergedProviderGamesByIds,
        },
      };
    }
    return state;
  }

  if (action.type === 'CLEAR_SEARCHED_GAMES') {
    return {
      ...state,
      filtered: {
        isLoading: true,
        games: [],
      },
    };
  }

  if (action.type === 'SET_ACTIVE_GAMES_CATEGORY') {
    return {
      ...state,
      activeGamesCategory: {
        ...state.activeGamesCategory,
        ...action.payload,
      },
    };
  }

  if (action.type === 'SET_GAME') {
    return { ...state, activeGame: action.payload };
  }

  if (action.type === 'SET_FAVORITE_GAMES') {
    const { categories, favoriteGames } = action.payload;

    return {
      ...state,
      categories,
      favoriteGames,
    };
  }

  if (action.type === 'RESET_GAMES') {
    return initialState;
  }

  if (action.type === 'RESET_DISPLAY_COUNT') {
    const { category } = action.payload;

    if (action.payload.category) {
      const id = action.payload.category?.id;

      if (id && category?.id) {
        const gameCategory = {
          ...action.payload.category,
          displayGamesCount: category.initialCount,
        };

        return {
          ...state,
          gamesByCategories: {
            ...state.gamesByCategories,
            [id]: { ...gameCategory },
          },
        };
      }
    }
  }

  return state;
};

export default gamesReducer;

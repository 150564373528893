/* eslint-disable import/prefer-default-export */

import { useFetchGames, GamesKeys } from './useFetchGames';
import usePlayer from '../usePlayer';
import { getSessionCookie } from '../../components/Helpers';

export function useRecommendedGames() {
  const { player } = usePlayer();
  const session = getSessionCookie();
  const partyId = session?.partyId ?? player?.partyId;
  const hasPartyID = !!partyId;

  return useFetchGames(
    GamesKeys.recommendedGames,
    hasPartyID ? 'recommended_for_you' : 'because_you_played',
    partyId,
    true
  );
}

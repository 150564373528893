import CMSApi from './CMSApi';

class defaultCmsApi extends CMSApi {
  constructor(baseUrl, authToken) {
    super(baseUrl, authToken);
    this.baseUrl = baseUrl;
  }


  checkDomain = async (domain) => {
    try {
      const res = await this.client.get('player-domains/blacklisted/', {
        params: {
          domain_name: domain
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      return { error: error.message, status: error.request.status };
    }
  };

  getPlayerRegCheck = async (params) => {
    try {
      const res = await this.client.get('player-reg-checks/', {
        params: {
          ...params,
          status: 'active',
        },
      });
      return res.data;
    } catch (error) {
      console.error('Error fetching player registration check:', error);
      return null;
    }
  };

  createPlayerRegCheck = async (body) => {
    try {
      const res = await this.client.post('player-reg-checks/', {
        ...body,
        site_name: process.env.REACT_APP_FRONTEND_URL,
      });
      return res.data;
    } catch (error) {
      console.error('Error fetching player registration check:', error);
      return null;
    }
  };
}

export const DefaultCmsApi = new defaultCmsApi(
  process.env.REACT_APP_API_BASE_URL,
  process.env.REACT_APP_CMS_AUTHORIZATION
);
